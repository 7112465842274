<template>
	<div class="page-help loaded">
		<div class="content plain">
			<h1>Help &amp; support</h1>
			<tab-nav v-model="selected" :items="tabs" class="mb-4" />
			<transition name="fade" mode="out-in">
				<div v-if="!selected" key="d0">
					<h2>
						Login
					</h2>
					<p v-if="hasChat">
						The Log in feature allows you to resume your report, view your completed report, and also chat anonymously with either PKF or your organisation’s nominated Disclosure Officers.
					</p>
					<p v-else>
						The Log in feature allows you to resume your report and view your completed report with status updates.
					</p>
					<p class="icon icon-resume">
						<b>
							Resume report
						</b><br>
						If you created an account and saved your report in progress, you can resume (and then submit) your report after you login.
					</p>
					<p class="icon icon-export">
						<b>
							Download report
						</b><br>
						If you have submitted a report and created an account, you can
						download a copy of your report at any time.
					</p>
					<p class="icon icon-export rotate-icon-180">
						<b>
							Upload additional documentation
						</b><br>
						Once you have submitted your report with an account, you are able to log back in and see the status of your report and provide any additional documentation to support your report.
					</p>
				</div>
				<div v-else-if="selected == 1" key="d1">
					<h2>FAQs</h2>
					<p class="bold">
						What is the PKF hotline service?
					</p>
					<p>
						The PKF hotline is a service that provides an avenue for you to report anonymously, or in confidence, actual or suspected incidents of unethical behaviour. 
					</p>
					<p>
						It is operated by an independent organisation named PKF Integrity Services (BMNS) Pty Ltd (“PKF”).
					</p>
					<p class="bold">
						How do I make a report?
					</p>
					<p>
						There are three ways you can make a report: 
					<ol>
						<li>Online, by clicking “Submit Report”</li>
						<li>Telephone, using the 1800 number</li>
						<li v-if="org.email">Email, using the address <a :href="'mailto:'+org.email">{{org.email}}</a></li>
					</ol>
					</p>
					<p class="bold">
						What will happen with my report? 
					</p>
					<p>
						PKF will report the information you provide to nominated Disclosure Officer/s within your organisation, through a secure Disclosure Management System, and in one business day of receipt. Your identity will not be made known to your organisation unless you authorise it. 
					</p>
					<p>It is then the role of your organisation to assess your report and determine whether or not the information provided warrants investigation.</p>
					<p class="bold">
						How do I know my report will remain confidential?
					</p>
					<p>
						PKF are independent and have significant expertise in handling whistleblowers. We have also designed a reporting platform that further protects the confidentiality of whistleblowers.  
					</p>
					<p>Your organisation is not involved in the operation of the PKF hotline service or the collation of information relating to your report, that is the responsibility of PKF. </p>
					<p>When using the service, your identity will never be made known to your organisation unless you authorise it.</p>
					<p class="bold">
						Will I be contacted after making my report or to assist with any investigation?
					</p>
					<p>
						You may be contacted after submitting your report. There is a message board function that allows you to communicate with PKF and your organisation (separately or as a group). 
					</p>
					<p>The organisation or PKF may wish to contact you to seek further information to assist with the investigation of your report, or to provide you with a status update or details of the outcome. There may also be instances when you either want to provide further information to your organisation or have a query.</p>
					<p v-if="hasChat">To access the message board function you will need to login to your account, using your Report ID and password that you created when you initially submitted your report. It will be helpful if you regularly login and check the message board.</p>
					<p class="bold">What if I have forgotten my Report ID?</p>
					<p>If you have forgotten your Report ID you will not be able to access the message board function. You will need to instead submit another report, in order to provide further information to your organisation or to raise a query.</p>
					<p class="bold">What if I have forgotten my password?</p>
					<p>You can reset your password by clicking “Login” and using your Report ID and the email address you provided when you initially submitted your report. </p>
					<p>If you did not set up an email address, you will not be able to reset your password online. You can however call PKF using the number in “Contact Support” to retrieve your password, but will need to provide your Report ID.</p>
</div>
				<div v-else-if="selected == 2" key="d2">
					<h2>Any questions?</h2>
					<p>
						Have a question, or want to find out more about the PKF hotline service?<br>
						<span class="text-black font-italic">
							For further information you can contact:
						</span>
					</p>
					<b-container class="p-0">
						<b-row>
							<b-col v-if="org.keyContacts.length" sm="6">
								<h3>{{ orgName }}</h3>
								<p v-for="contact in org.keyContacts" :key="contact.name">
									<b>{{ contact.name }}</b><br />
									<span v-if="contact.position">{{contact.position}}<br></span>
									<span v-if="contact.phone">P: {{contact.phone}}<br></span>
									<span v-if="contact.mobile">M: {{contact.mobile}}<br></span>
									<span v-if="contact.website">W: <a :href="contact.website" target="_blank">{{contact.website}}</a><br /></span>
									<span v-if="contact.email">E: <a :href="'mailto:'+contact.email" target="_blank">{{contact.email}}</a></span>
								</p>
							</b-col>
							<b-col v-else-if="org.code != 'DFLT'" sm="6">
								<h3>{{ orgName }}</h3>
								<p>
									{{ org.number1800 | format1800 }}<br />
									<a v-if="org.email" :href="'mailto:'+org.email">{{org.email}}</a>
								</p>
							</b-col>
							<b-col sm="6">
								<h3>PKF Integrity Services (BMNS) Pty Ltd</h3>
								<p>
									<b>Viv Mercer</b><br />
									Manager<br />
									P: <a href="tel:+61292376560">+61 2 9237 6560</a><br />
									M: <a href="tel:+61418478849">+61 418 478 849</a><br />
									E: <a href="mailto:vmercer@pkf.com.au">vmercer@pkf.com.au</a>
								</p>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</transition>
			<b-button to="/step1" size="lg" class="d-inline-flex align-items-center p-3 pr-35 mt-3"><icon-secure width="24" height="24" class="mr-3 mt-n1" /> Submit Report</b-button>
		</div>
	</div>
</template>
<script>
import { TabNav } from '@/components'
import { canChat } from "@/mixins";

export default {
	name: 'Help',
	components: {
		TabNav,
	},
	mixins: [canChat],
	data() {
		return {
			selected: 0,
		}
	},
	computed: {
		org() {
			return this.$store.state.org
		},
		tabs() {
			return ['Log In', 'FAQs', 'Contact Support']
		},
	},
	metaInfo: {
		title: 'Help',
	},
}
</script>

<style lang="scss">
.page-help{
	b{
		color: #000;
	}
	p.icon{
		margin-left: 4em;
		position: relative;
		&:before{
			content: '';
			position: absolute;
			left: -4em;
			top: 7px;
			width: 2em;
			height: 2em;
			background-repeat: no-repeat;
			background-size: contain;
		}
		&.icon-comment:before{
			background-image: url(/img/icons/comment.svg);
		}
		&.icon-export:before{
			background-image: url(/img/icons/export.svg);
		}
		&.icon-resume:before{
			background-image: url(/img/icons/resume.svg);
		}
		&.rotate-icon-180:before{
			transform: rotate(180deg);
		}
	}
	h3 {
		font-weight: normal;
	}
}
</style>